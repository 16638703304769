import React, { useState } from "react";
import Typewriter from 'typewriter-effect';
import "./Profile.css";
import ScrollService from "../../../Utilities/ScrollService";
import CVlanguage from "../../CVlanguage/CVlanguage";


export default function Profile() {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    return (
        <div className="profile-container">
            <div className="profile-parent">
                <div className="profile-details">
                    <div className="colz">
                        <div className="colz-icon">
                        <a href="https://wa.me/33778125502" target="_blank">
                                <i className="fa fa-whatsapp"></i>  
                         </a> 
                        <a href="https://www.instagram.com/ahmed__mellouli/?hl=fr" target="_blank">
                            <i className="fa fa-instagram"></i>
                        </a>
                        <a href="https://github.com/MellouliiAhmed" target="_blank">
                            <i className="fa fa-github"></i>
                        </a>
                        <a href="https://gitlab.com/mellouliiahmed" target="_blank">
                         <i className="fa fa-gitlab"></i>
                        </a>
                        <a href="https://www.linkedin.com/in/ahmedmellouli/" target="_blank">
                        <i className="fa fa-linkedin"></i>
                        </a>
                                
                        </div>
                    </div>

                    <div className="profile-details-name">
                        <span className="primary-text">
                            {" "}
                            Hello, I am <span className="highlighted">Ahmed</span>
                        </span>
                    </div>
                    <div className="profile-details-role">
                        <span className="primary-text">
                            {" "}
                            <h1>
                                {" "}
                                <Typewriter
                                    options={{
                                        strings: ['Proficient in Web Development',
                                         'Skilled in DevOps methodologies',
                                         'Experienced in Full Stack Development',
                                         'Competent with Kubernetes and Docker'],
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </h1>
                            <span className="profile-role-tagline">
                                knack of building applications with frontend and backend
                                operations.
                            </span>
                        </span>
                    </div>
                    <div className="profile-options">
                        <button className="btn primary-btn"
                        onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
                        >
                            {""}
                            Hire Me{" "}
                        </button>
                        {/* <a href="CV.pdf" download='CV.pdf'> */}
                            <button onClick={toggle} className="btn highlighted-btn">Get Resume</button>
                            <CVlanguage modal={modal} toggle={toggle} />
                        {/* </a> */}
                    </div>
                </div>
                <div className="profile-picture">
                    <div className="profile-picture-background">

                    </div>
                </div>
            </div>
        </div>
    )
}