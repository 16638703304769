/*import React,{useEffect} from "react";*/
import ScreenHeading from '../../Utilities/ScreenHeading/ScreenHeading'
import ScrollService from '../../Utilities/ScrollService'
import Animations from '../../Utilities/Animations';
import './AboutMe.css'
import CVlanguage from '../CVlanguage/CVlanguage';
import { useState } from 'react';



export default function AboutMe(props){
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    let fadeInScreenHandler= (screen)=>{
        if(screen.fadeInScreen !== props.id)
        return
        Animations.animations.fadeInScreen(props.id)
    }
   /* const fadeInSubscription = ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);*/
   const SCREEN_CONSTANTS = { 
    description: (
        <>
            Software engineering graduate from   
            <a href="https://www.esprit.tn/esprit/valeurs" className='university-link' target="_blank" rel="noopener noreferrer">
                 Esprit
            </a>, 
            specializing in web development, with strong adaptability and a solid team spirit. In addition to my web development expertise, I have hands-on experience in DevOps practices, including setting up CI/CD pipelines and managing Kubernetes clusters. I am currently seeking a professional opportunity where I can leverage my skills, contributing to innovative projects in a dynamic environment.
        </>
    ),
    highlights: {
        bullets: [
            "Full Stack web and mobile development",
            "Interactive Frontend as per the design",
            "React and React Native",
            "Backend development with Spring Boot and NodeJS",
            "Building and managing Kubernetes clusters",
            "Implementing CI/CD pipelines with Jenkins",
            "Automating deployments using ArgoCD on Kubernetes",
        ],
        heading: "Here are a Few Highlights:"
    }
};

    const renderHighlight =() =>{
        return(
            SCREEN_CONSTANTS.highlights.bullets.map((value, i)=>(
                <div className="highlight" key={i}>
                    <div className="highlight-blob"></div>
                    <span>{value}</span>
                </div>
            ))
        )
    }
    return(
        <div className="about-me-container screen-container " id={props.id}>
            <div className="about-me-parent">
                <ScreenHeading title={"About Me"} subHeading={"Why Choose Me?"}/>
                <div className="about-me-card">
                    <div className="about-me-profile"></div>
                    <div className="about-me-details">
                        <span className="about-me-description">{SCREEN_CONSTANTS.description}</span>
                        <div className="about-me-highlights">
                            <div className="highlight-heading">
                                <span>{SCREEN_CONSTANTS.highlights.heading}</span>
                            </div>
                            {renderHighlight()}
                        </div>
                        <div className="about-me-options">
                        <button className="btn primary-btn"
                        onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
                        >
                            {""}
                            Hire Me{" "}
                        </button>
                        <button onClick={toggle} className="btn highlighted-btn">Get Resume</button>
                        <CVlanguage modal={modal} toggle={toggle} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}