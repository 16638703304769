import React from "react";
import OwlCarousel from "react-owl-carousel"
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import ScreenHeading from "../../Utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../Utilities/ScrollService";
import Animations from "../../Utilities/Animations";
import "./Testimonial.css"
import lady from '../../../src/img/Testimonial/lady.png'
import mike from '../../../src/img/Testimonial/mike.png'
import man from '../../../src/img/Testimonial/man.png'
import remy from '../../../src/img/Testimonial/hiphen/remy.png'
import eenzo from '../../../src/img/Testimonial/hiphen/eenzo.png'
import reeno from '../../../src/img/Testimonial/hiphen/reeno.png'
import josss from '../../../src/img/Testimonial/hiphen/josss.png'
import shape from '../../../src/img/Testimonial/shape-bg.png'



export default function Testimonial(props) {

    let fadeInScreenHandler = (screen) => {
        if (screen.fadeScreen !== props.id)
            return
        Animations.animations.fadeInScreen(props.id)
    }
    const fadeInSubscription =
        ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);



    const options = {
        loop: true,
        margin: 0,
        nav: true,
        animateIn: "bounceInRight",
        animateOut: "bounceOutRight",
        dots: true,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            1000: {
                items: 3,
            },
        }
    }


    return (
        <div>
            <ScreenHeading
                title={"Testimonial"}
                subHeading={"What My Client Say About Me"}
            />
            <section className="testimonial-section" id={props.id || ''}>
                <div className="container">
                    <div className="row">
                        <OwlCarousel className="owl-carousel" id="testimonial-carousel" {...options}>



                            <div className="col-lg-12">
                                <div className="testi-item">
                                    <div className="testi-comment">
                                        <p>
                                            <i className="fa fa-quote-left" />
                                            Ahmed has shown outstanding dedication and technical ability,
                                             playing a crucial role in the project's success.
                                            <i className="fa fa-quote-right" />
                                        </p>
                                        <ul className="stars list-unstyled">
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star-half-alt" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="client-info">
                                        <img src={josss} alt="no internet connection"></img>
                                        <h5>Joss GILLET</h5>
                                        <p>CTO at HIPHEN</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="testi-item">
                                    <div className="testi-comment">
                                        <p>
                                            <i className="fa fa-quote-left" />
                                            Ahmed brings expertise and positivity,
                                             making valuable contributions that improve both functionality and performance.
                                            <i className="fa fa-quote-right" />
                                        </p>
                                        <ul className="stars list-unstyled">
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star-half-alt" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="client-info">
                                        <img src={eenzo} alt="no internet connection"></img>
                                        <h5>Enzo GUENY</h5>
                                        <p>Web developer at HIPHEN</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="testi-item">
                                    <div className="testi-comment">
                                        <p>
                                            <i className="fa fa-quote-left" />
                                            Ahmed is a skilled problem-solver and a great collaborator,
                                             always producing clean and efficient code.
                                            <i className="fa fa-quote-right" />
                                        </p>
                                        <ul className="stars list-unstyled">
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star-half-alt" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="client-info">
                                        <img src={reeno} alt="no internet connection"></img>
                                        <h5>Renaud AUBERT</h5>
                                        <p>Web developer at HIPHEN</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="testi-item">
                                    <div className="testi-comment">
                                        <p>
                                            <i className="fa fa-quote-left" />
                                            Ahmed is a key contributor with excellent problem-solving
                                             skills and a collaborative attitude, consistently delivering on time.
                                            <i className="fa fa-quote-right" />
                                        </p>
                                        <ul className="stars list-unstyled">
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star-half-alt" />
                                            </li>
                                            <li>
                                                <i className="fa fa-star" />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="client-info">
                                        <img src={remy} alt="no internet connection"></img>
                                        <h5>Remy CASUBOLO</h5>
                                        <p>Team Lead IT at HIPHEN</p>
                                    </div>
                                </div>
                            </div>



                        </OwlCarousel>
                    </div>
                </div>
            </section>
            <div className="footer-image">
                <img src={shape} alt="Phot0 not responding" />
            </div>

        </div>
    )
}