import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./cVlanguage.css";

export default function CVlanguage({ modal, toggle }) {
    return (
        <Modal isOpen={modal} toggle={toggle} fade={true}>
            <ModalHeader className="modal-title">My Resume</ModalHeader>
            <ModalBody className="modal-body">
                <p>If you want to download the resume in French or English, please choose:</p>
                <div className="language-buttons">
                    <a href="MELLOULIAHMED_CV_FR.pdf" download='MELLOULIAHMED_CV_FR.pdf'>
                            <button className="btn french-cv">French</button>
                        </a>
                        <a href="MELLOULIAHMED_CV_EN.pdf" download='MELLOULIAHMED_CV_EN.pdf'>
                            <button className="btn english-cv">English</button>
                        </a>
                </div>
            </ModalBody>
        </Modal>
    );
}
